// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Button, Heading, LinkButton } from '@storylinerlabs/design';
import { useImmerAtom } from 'jotai/immer';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

// import config
import { storylinerWebAppDomain } from '../../../config';

// import components
import LinkComponent from '../atoms/LinkComponent';

// import modules
import { earlyAccessModalAtom } from '../../modules/jotai';

// import styles
import styles from './MainNavMobileMenu.module.css';

// MainNav component
const MainNavMobileMenu: React.FunctionComponent<{ closeMenu: () => void }> = ({ closeMenu }) => {
  // init hooks
  const [, setEarlyAccessModalState] = useImmerAtom(earlyAccessModalAtom);
  const { t } = useTranslation('navigations');
  const { asPath } = useRouter();

  // init render
  return (
    <div className={styles['main-nav-mobile-menu-container']}>
      <div className={styles['main-nav-mobile-menu-inner-container']}>
        <div className={styles['main-nav-mobile-menu-close-button']}>
          <Button color="naked" icon="ri-close-line" onClick={closeMenu} />
        </div>

        <div className={styles['main-nav-mobile-menu-section']}>
          <div className={styles['main-nav-mobile-menu-item']}>
            <Heading alignment="center" tag="label">
              {t('MainNav.platform.label')}
            </Heading>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.howItWorks.href')}>
              <LinkButton
                color={t('MainNav.howItWorks.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.howItWorks.label')}
              />
            </LinkComponent>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.features.href')}>
              <LinkButton
                color={t('MainNav.features.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.features.label')}
              />
            </LinkComponent>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.genres.href')}>
              <LinkButton
                color={t('MainNav.genres.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.genres.label')}
              />
            </LinkComponent>
          </div>
        </div>

        <div className={styles['main-nav-mobile-menu-section']}>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.pricing.href')}>
              <LinkButton
                color={t('MainNav.pricing.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.pricing.label')}
              />
            </LinkComponent>
          </div>
        </div>

        <div className={styles['main-nav-mobile-menu-section']}>
          <div className={styles['main-nav-mobile-menu-item']}>
            <Heading alignment="center" tag="label">
              {t('MainNav.resources.label')}
            </Heading>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.blog.href')}>
              <LinkButton
                color={t('MainNav.blog.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.blog.label')}
              />
            </LinkComponent>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.helpCenter.href')}>
              <LinkButton
                color={t('MainNav.helpCenter.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.helpCenter.label')}
              />
            </LinkComponent>
          </div>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent href={t('MainNav.releaseNotes.href')}>
              <LinkButton
                color={t('MainNav.releaseNotes.href') === asPath ? 'ultraLight' : 'naked'}
                text={t('MainNav.releaseNotes.label')}
              />
            </LinkComponent>
          </div>
        </div>

        <div className={styles['main-nav-mobile-menu-section']}>
          <div className={styles['main-nav-mobile-menu-item']}>
            <LinkComponent
              href={`${storylinerWebAppDomain}${t('MainNav.signin.href')}`}
              target="_self"
            >
              <LinkButton color="naked" customClasses="!mr-1" text={t('MainNav.signin.label')} />
            </LinkComponent>
          </div>

          <div className={styles['main-nav-mobile-menu-item']}>
            <Button
              color="dark"
              onClick={() => setEarlyAccessModalState(() => true)}
              text={t('MainNav.signup.label')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MainNavMobileMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

MainNavMobileMenu.displayName = 'MainNavMobileMenu';

export default MainNavMobileMenu;
