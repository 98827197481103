// import node_modules
import * as React from 'react';
import { Button, LinkButton, LogoLettering } from '@storylinerlabs/design';
import { useImmer } from 'use-immer';
import { useImmerAtom } from 'jotai/immer';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

// import components
// import CasesDropdownMenu from '../menus/CasesDropdownMenu';
import LinkComponent from '../atoms/LinkComponent';
import MainNavMobileMenu from '../menus/MainNavMobileMenu';
import PlatformDropdownMenu from '../menus/PlatformDropdownMenu';
import ResourcesDropdownMenu from '../menus/ResourcesDropdownMenu';

// import modules
import { earlyAccessModalAtom } from '../../modules/jotai';

// import config
import { storylinerWebAppDomain } from '../../../config';

// import styles
import styles from './MainNav.module.css';

// MainNav component
const MainNavComponent: React.FunctionComponent = () => {
  // init hooks
  const [, setEarlyAccessModalState] = useImmerAtom(earlyAccessModalAtom);
  const { t } = useTranslation('navigations');
  const { asPath } = useRouter();

  // init states
  const [platformDropdownState, setPlatformDropdownState] = useImmer(false);
  const [resourcesDropdownState, setResourcesDropdownState] = useImmer(false);
  // const [casesDropdownState, setCasesDropdownState] = useImmer(false);
  const [mobileMenuState, setMobileMenuState] = useImmer(false);
  // const [casesReferenceElement] = useImmer<HTMLButtonElement | null>(null);
  const [resourcesReferenceElement, setResourcesReferenceElement] =
    useImmer<HTMLButtonElement | null>(null);
  const [platformReferenceElement, setPlatformReferenceElement] =
    useImmer<HTMLButtonElement | null>(null);

  // init func
  const onTogglePlatformDropdown = React.useCallback(() => {
    setPlatformDropdownState(!platformDropdownState);
  }, [platformDropdownState, setPlatformDropdownState]);

  const onToggleResourcesDropdown = React.useCallback(() => {
    setResourcesDropdownState(!resourcesDropdownState);
  }, [resourcesDropdownState, setResourcesDropdownState]);

  // const onToggleCasesDropdown = React.useCallback(() => {
  //   setCasesDropdownState(!casesDropdownState);
  // }, [casesDropdownState, setCasesDropdownState]);

  const onHidePlatformDropdown = React.useCallback(() => {
    setPlatformDropdownState(false);
  }, [setPlatformDropdownState]);

  const onHideResourcesDropdown = React.useCallback(() => {
    setResourcesDropdownState(false);
  }, [setResourcesDropdownState]);

  // const onHideCasesDropdown = React.useCallback(() => {
  //   setCasesDropdownState(false);
  // }, [setCasesDropdownState]);

  const onToggleMobileMenu = React.useCallback(() => {
    setMobileMenuState(!mobileMenuState);
  }, [mobileMenuState, setMobileMenuState]);

  // init render
  return (
    <>
      <div className={styles['main-nav-container']}>
        <div className={styles['main-nav-inner-container']}>
          <div className={styles['main-nav-logo-container']}>
            <LinkComponent
              href={t('MainNav.logo.href')}
              ariaLabel={t('MainNav.logo.label')}
              title={t('MainNav.logo.label')}
            >
              <LogoLettering />
            </LinkComponent>
          </div>

          <div className={styles['main-nav-menu']}>
            <div className={styles['main-nav-menu-item']}>
              <Button
                color="naked"
                customClasses="!mr-1"
                iconRight="ri-arrow-down-s-line"
                onClick={onTogglePlatformDropdown}
                ref={setPlatformReferenceElement}
                text={t('MainNav.platform.label')}
              />
            </div>

            {/* <div className={styles["main-nav-menu-item"]}>
              <Button
                color="naked"
                iconRight="ri-arrow-down-s-line"
                onClick={onToggleCasesDropdown}
                ref={casesDropdownRef}
                text={t('MainNav.useCases.label')}
              />
            </div> */}

            <div className={styles['main-nav-menu-item']}>
              <LinkComponent href={t('MainNav.pricing.href')}>
                <LinkButton
                  color={t('MainNav.pricing.href') === asPath ? 'ultraLight' : 'naked'}
                  customClasses="!mr-1"
                  text={t('MainNav.pricing.label')}
                />
              </LinkComponent>
            </div>

            <div className={styles['main-nav-menu-item']}>
              <Button
                color="naked"
                iconRight="ri-arrow-down-s-line"
                text={t('MainNav.resources.label')}
                onClick={onToggleResourcesDropdown}
                ref={setResourcesReferenceElement}
              />
            </div>
          </div>

          {/* Desktop cta */}
          <div className={styles['main-nav-menu']}>
            <div className={styles['main-nav-menu-item']}>
              <LinkComponent
                href={`${storylinerWebAppDomain}${t('MainNav.signin.href')}`}
                target="_self"
              >
                <LinkButton color="naked" customClasses="!mr-1" text={t('MainNav.signin.label')} />
              </LinkComponent>
            </div>

            <div className={styles['main-nav-menu-item']}>
              <Button
                color="dark"
                onClick={() => setEarlyAccessModalState(() => true)}
                text={t('MainNav.signup.label')}
              />
            </div>
          </div>

          {/* Mobile menu button */}
          <div className={styles['main-nav-menu-mobile-buttons']}>
            <div className={styles['main-nav-menu-item']}>
              <Button
                customClasses="!mr-1"
                size="small"
                color="dark"
                onClick={() => setEarlyAccessModalState(() => true)}
                text={t('MainNav.signup.label')}
              />
            </div>

            <div className={styles['main-nav-menu-item']}>
              <Button color="naked" icon="ri-menu-line" onClick={onToggleMobileMenu} size="small" />
            </div>
          </div>
        </div>
      </div>

      {/* Platform menu */}
      <PlatformDropdownMenu
        hideDropdown={onHidePlatformDropdown}
        isActive={platformDropdownState}
        ref={platformReferenceElement as unknown as React.Ref<HTMLButtonElement>}
      />

      {/* Use cases menu */}
      {/* <CasesDropdownMenu
        hideDropdown={onHideCasesDropdown}
        isActive={casesDropdownState}
        ref={casesReferenceElement as unknown as React.Ref<HTMLButtonElement>}
      /> */}

      {/* Resources menu */}
      <ResourcesDropdownMenu
        hideDropdown={onHideResourcesDropdown}
        isActive={resourcesDropdownState}
        ref={resourcesReferenceElement as unknown as React.Ref<HTMLButtonElement>}
      />

      {/* Overlay full screen mobile nav */}
      {mobileMenuState && <MainNavMobileMenu closeMenu={onToggleMobileMenu} />}
    </>
  );
};

MainNavComponent.displayName = 'MainNav';

export default MainNavComponent;
