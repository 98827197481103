/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/ban-ts-comment */

// import node_modules
import * as React from 'react';

// import types
import { Locale } from '../types';

// define hooks
export const useLocalization = (): {
  getBrowserLang: () => Locale;
  localizePathname: (lang: Locale, pathname: string) => string;
} => {
  // init func
  const onGetBrowserLang = React.useCallback(() => {
    // get browser lang
    // @ts-ignore
    const browserLang = navigator?.language || navigator?.userLanguage;
    const langCode = (browserLang || 'en') as Locale;
    const serializedLang = langCode.includes('de') ? 'de' : 'en';
    return serializedLang;
  }, []);

  const onLocalizePathname = React.useCallback((nextLocale: Locale, currentPathname: string) => {
    // auth callback
    if (currentPathname === '/callback/') {
      return '/de/callback/';
    }
    if (currentPathname === '/de/callback/') {
      return '/callback/';
    }

    // auth confirm
    if (currentPathname === '/confirm/') {
      return '/de/confirm/';
    }
    if (currentPathname === '/de/confirm/') {
      return '/confirm/';
    }

    // auth signout
    if (currentPathname === '/signout/') {
      return '/de/signout/';
    }
    if (currentPathname === '/de/signout/') {
      return '/signout/';
    }

    // auth signin
    if (currentPathname === '/signin/') {
      return '/de/signin/';
    }
    if (currentPathname === '/de/signin/') {
      return '/signin/';
    }

    // auth signup
    if (currentPathname === '/signup/') {
      return '/de/signup/';
    }
    if (currentPathname === '/de/signup/') {
      return '/signup/';
    }

    // auth verify
    if (currentPathname === '/verify/') {
      return '/de/verify/';
    }
    if (currentPathname === '/de/verify/') {
      return '/verify/';
    }

    // home
    if (currentPathname === '/') {
      return '/de/';
    }
    if (currentPathname === '/de/') {
      return '/';
    }

    // about
    if (currentPathname === '/about/') {
      return '/de/ueber-uns/';
    }
    if (currentPathname === '/de/ueber-uns/') {
      return '/about/';
    }

    // community
    if (currentPathname === '/community/') {
      return '/de/community/';
    }
    if (currentPathname === '/de/community/') {
      return '/community/';
    }

    // features
    if (currentPathname === '/features/') {
      return '/de/features/';
    }
    if (currentPathname === '/de/features/') {
      return '/features/';
    }

    // how it works
    if (currentPathname === '/how-it-works/') {
      return '/de/wie-es-funktioniert/';
    }
    if (currentPathname === '/de/wie-es-funktioniert/') {
      return '/how-it-works/';
    }

    // legal notice
    if (currentPathname === '/legal-notice/') {
      return '/de/impressum/';
    }
    if (currentPathname === '/de/impressum/') {
      return '/legal-notice/';
    }

    // manifesto
    if (currentPathname === '/manifesto/') {
      return '/de/manifest/';
    }
    if (currentPathname === '/de/manifest/') {
      return '/manifesto/';
    }

    // newsletter confirmed
    if (currentPathname === '/newsletter-registration-confirmed/') {
      return '/de/newsletter-anmeldung-bestaetigt/';
    }
    if (currentPathname === '/de/newsletter-anmeldung-bestaetigt/') {
      return '/newsletter-registration-confirmed/';
    }

    // newsletter thank you
    if (currentPathname === '/newsletter-registration-thank-you/') {
      return '/de/newsletter-anmeldung-danke/';
    }
    if (currentPathname === '/de/newsletter-anmeldung-danke/') {
      return '/newsletter-registration-thank-you/';
    }

    // early access confirmed
    if (currentPathname === '/early-access-confirmed/') {
      return '/de/early-access-bestaetigt/';
    }
    if (currentPathname === '/de/early-access-bestaetigt/') {
      return '/early-access-confirmed/';
    }

    // early access thank you
    if (currentPathname === '/early-access-thank-you/') {
      return '/de/early-access-danke/';
    }
    if (currentPathname === '/de/early-access-danke/') {
      return '/early-access-thank-you/';
    }

    // press
    if (currentPathname === '/press/') {
      return '/de/presse/';
    }
    if (currentPathname === '/de/presse/') {
      return '/press/';
    }

    // pricing
    if (currentPathname === '/pricing/') {
      return '/de/preise/';
    }
    if (currentPathname === '/de/preise/') {
      return '/pricing/';
    }

    // privacy
    if (currentPathname === '/privacy/') {
      return '/de/datenschutz/';
    }
    if (currentPathname === '/de/datenschutz/') {
      return '/privacy/';
    }

    // terms of service
    if (currentPathname === '/terms-of-service/') {
      return '/de/nutzungsbedingungen/';
    }
    if (currentPathname === '/de/nutzungsbedingungen/') {
      return '/terms-of-service/';
    }

    // blog
    if (currentPathname === '/blog/') {
      return '/de/blog/';
    }
    if (currentPathname === '/de/blog/') {
      return '/blog/';
    }

    // news
    if (currentPathname === '/blog/tag/news/') {
      return '/de/blog/tag/news/';
    }
    if (currentPathname === '/de/blog/tag/news/') {
      return '/blog/tag/news/';
    }

    // help center
    if (currentPathname === '/help-center/') {
      return '/de/help-center/';
    }
    if (currentPathname === '/de/help-center/') {
      return '/help-center/';
    }

    // release notes
    if (currentPathname === '/changelog/') {
      return '/de/changelog/';
    }
    if (currentPathname === '/de/changelog/') {
      return '/changelog/';
    }

    // genres
    if (currentPathname === '/genres/') {
      return '/de/genres/';
    }
    if (currentPathname === '/de/genres/') {
      return '/genres/';
    }

    // return home as default
    return nextLocale === 'de' ? '/de/' : '/';
  }, []);

  // return hook
  return {
    getBrowserLang: onGetBrowserLang,
    localizePathname: onLocalizePathname,
  };
};

export default useLocalization;
