// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Dropdown, DropdownLinkItem, DropdownMenu } from '@storylinerlabs/design';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

// import components
import LinkComponent from '../atoms/LinkComponent';

// define components
const PlatformDropdownMenu = React.forwardRef<
  HTMLButtonElement,
  { isActive: boolean; hideDropdown: () => void }
>(({ hideDropdown, isActive }, ref) => {
  // init hooks
  const { asPath } = useRouter();
  const { t } = useTranslation('navigations');

  // init render
  return (
    <Dropdown hideDropdown={hideDropdown} isActive={isActive} placement="bottom" ref={ref}>
      <DropdownMenu isActive={isActive}>
        <DropdownLinkItem
          LinkComponent={({ children }: { children: React.ReactNode }) => (
            <LinkComponent href={t('MainNav.howItWorks.href')}>{children}</LinkComponent>
          )}
          text={t('MainNav.howItWorks.label')}
          isActive={t('MainNav.howItWorks.href') === asPath}
        />
        <DropdownLinkItem
          LinkComponent={({ children }: { children: React.ReactNode }) => (
            <LinkComponent href={t('MainNav.features.href')}>{children}</LinkComponent>
          )}
          text={t('MainNav.features.label')}
          isActive={t('MainNav.features.href') === asPath}
        />
        <DropdownLinkItem
          LinkComponent={({ children }: { children: React.ReactNode }) => (
            <LinkComponent href={t('MainNav.genres.href')}>{children}</LinkComponent>
          )}
          text={t('MainNav.genres.label')}
          isActive={t('MainNav.genres.href') === asPath}
        />
      </DropdownMenu>
    </Dropdown>
  );
});

PlatformDropdownMenu.propTypes = {
  hideDropdown: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

PlatformDropdownMenu.displayName = 'PlatformDropdownMenu';

export default PlatformDropdownMenu;
