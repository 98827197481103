/* eslint-disable consistent-return */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import cn from 'classnames';
import { useImmer } from 'use-immer';
import { useImmerAtom } from 'jotai/immer';
import { useRouter } from 'next/router';

// import types
import { Locale, RouteEnum } from '../../types';

// import components
import EarlyAccessModal from '../modals/EarlyAccessModal';
import MainNav from '../navigations/MainNav';
import ThemeHead from '../meta/ThemeHead';
import WebsiteFooter from '../navigations/Footer';

// import modules
import { localeAtom } from '../../modules/jotai';
import { useLocalization } from '../../hooks/use-localization';

// import styles
import styles from './MainLayout.module.css';

// Layout component
const MainLayout: React.FunctionComponent<{ children: React.ReactNode; hrefLang?: string }> = ({
  children,
  hrefLang,
}) => {
  // init hooks
  const [locale] = useImmerAtom(localeAtom);
  const router = useRouter();
  const { localizePathname } = useLocalization();

  // init state
  const [isScrollingState, setIsScrollingState] = useImmer<boolean>(false);

  // init func
  const onChangeLocale = React.useCallback(
    (nextLocale: Locale) => {
      if (nextLocale === locale) return;
      const localizedPathname = localizePathname(nextLocale, router.asPath);
      router.push(hrefLang || localizedPathname || RouteEnum.WEBSITE_HOME_EN);
    },
    [locale, localizePathname, hrefLang, router],
  );

  // handle data changes
  React.useEffect(() => {
    const onScroll = () => {
      if (window.scrollY === 0) {
        setIsScrollingState(false);
      } else {
        setIsScrollingState(true);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setIsScrollingState]);

  // scroll to position on page
  React.useEffect(() => {
    if (typeof window !== 'undefined' && window?.location?.hash) {
      const { hash } = window.location;
      const element = document.getElementById(hash);
      // check if element exists
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, []);

  // init styles
  const headerStyles = React.useMemo(
    () => cn(styles.header, { [styles.headerIsScrolling]: isScrollingState }),
    [isScrollingState],
  );

  return (
    <>
      <ThemeHead />

      <div className={styles.container} id="top">
        <div className={headerStyles}>
          <MainNav />
        </div>
        <div className={styles.main}>{children}</div>
        <div className={styles.footer}>
          <WebsiteFooter changeLocale={onChangeLocale} />
        </div>
      </div>

      <EarlyAccessModal />
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
